import { getPlatformFromCookie, isWebview } from '@/utils'

declare global {
  interface Navigator {
    userAgentData?: {
      platform: string
      getHighEntropyValues?: (hints: string[]) => Promise<{ platform: string }>
    }
  }
}

export enum DeviceType {
  androidApp = 'androidApp',
  androidWeb = 'androidWeb',
  iosApp = 'iosApp',
  iosWeb = 'iosWeb',
  web = 'web',
}

const BROWSER_PATTERNS = {
  SAFARI: /Safari/,
  CHROME: /Chrome/,
  CHROME_IOS: /CriOS/,
  FIREFOX_IOS: /FxiOS/,
} as const

/**
 * iOS 디바이스인지 판별
 */
export const isIOS = () => {
  if (typeof window === 'undefined') return false

  const userAgent = navigator.userAgent.toLowerCase()
  const maxTouchPoints = navigator.maxTouchPoints || 0

  // iPadOS 13+ 감지
  const isIPadOS = /macintosh|mac os x/i.test(userAgent) && maxTouchPoints > 1

  return /iphone|ipad|ipod/.test(userAgent) || isIPadOS
}

/**
 * 디바이스 타입을 판별하여 반환
 * @returns
 * androidApp: 안드로이드 앱
 * androidWeb: 안드로이드 웹
 * iosWeb: iOS 웹
 * web: 웹
 */
export const detectDeviceType = () => {
  const platform = getPlatformFromCookie()

  if (isWebview() && platform === 'android') return DeviceType.androidApp
  if (isWebview() && platform === 'ios') return DeviceType.iosApp
  if (/Android/i.test(navigator.userAgent)) return DeviceType.androidWeb
  if (isIOS()) return DeviceType.iosWeb

  return DeviceType.web
}

/**
 * 데스크톱 OS를 판별하여 반환
 */
export const detectOS = () => {
  if (typeof window === 'undefined') return 'Unknown'
  if ('userAgentData' in navigator && navigator.userAgentData?.platform) {
    return navigator.userAgentData.platform
  }
  // Fallback for older browsers
  const userAgent = navigator.userAgent
  if (userAgent.includes('Macintosh')) {
    return 'macOS'
  } else if (userAgent.includes('Windows')) {
    return 'Windows'
  } else {
    return 'Other'
  }
}

/**
 * Safari 브라우저인지 판별
 */
export const isSafari = () => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator.userAgent

  // User-Agent를 기반으로 Safari와 다른 브라우저를 구분
  const isSafariUserAgent =
    // Safari 브라우저인지 확인
    BROWSER_PATTERNS.SAFARI.test(userAgent) &&
    // 다른 브라우저(특히 iOS용 Chrome, Firefox)는 WebKit을 기반으로 만들어졌기 때문에
    // User-Agent 문자열에 Safari를 포함하고 있어도 제외해야 함
    !BROWSER_PATTERNS.CHROME.test(userAgent) && // 데스크톱 또는 안드로이드용 Chrome
    !BROWSER_PATTERNS.CHROME_IOS.test(userAgent) &&
    !BROWSER_PATTERNS.FIREFOX_IOS.test(userAgent)

  return isSafariUserAgent
}
