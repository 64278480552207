import { useCallback } from 'react'
import { useAtom } from 'jotai'
import { INITIAL_VIRTUOSO_SNAPSHOT, virtuosoAtom } from '@/stores'

/**
 * virtuoso 리스트 스냅샷 초기화하는 커스텀 훅
 * @returns {reset} 스냅샷 초기화 함수
 */
const useVirtuosoSnapshotReset = () => {
  const [, setSnapshotState] = useAtom(virtuosoAtom)

  const resetScroll = useCallback(() => {
    setSnapshotState(INITIAL_VIRTUOSO_SNAPSHOT)
  }, [setSnapshotState])

  return {
    resetScroll,
  }
}

export default useVirtuosoSnapshotReset
