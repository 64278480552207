import { twMerge } from 'tailwind-merge'

/**
 * 새로운 알림이 있는 경우 노출되는 빨간 점 컴포넌트
 */
const NewNoti = (props: React.ComponentPropsWithoutRef<'span'>) => {
  return (
    <span
      className={twMerge(
        'block',
        'absolute',
        'top-[17px]',
        'left-[26px]',
        'w-[6px]',
        'h-[6px]',
        'bg-red-500',
        'rounded-[3px]',
        props.className,
      )}
    />
  )
}

export default NewNoti
