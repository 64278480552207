import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { INITIAL_TOKENS, TOKENS_KEY } from '@/consts'
import { tokensAtom } from '@/stores'
import { getLocalStorage } from '@/utils'
import type { AuthInfo } from '@/utils/auth'
import { getUserRoleFromToken } from '@/utils/token'

/**
 * 로그인 여부와 사용자 권한을 반환합니다.
 * @example
 * const { isLoggedIn, role } = useAuth()
 * @returns  isLoggedIn: 로그인 여부, role: 사용자 권한
 */
const useAuth = (): AuthInfo => {
  const [tokens, setTokens] = useAtom(tokensAtom)
  const isClient = typeof window !== 'undefined'
  const { refreshToken: storedRefreshToken } = getLocalStorage(
    TOKENS_KEY,
    INITIAL_TOKENS,
  )

  const isTokenRemoved = !storedRefreshToken

  useEffect(() => {
    // 로컬스토리지에서 직접 제거된 경우 tokensAtom 또한 초기화해준다
    if (isTokenRemoved) setTokens(INITIAL_TOKENS)
  }, [isTokenRemoved, setTokens])

  const isLoggedIn = isTokenRemoved
    ? false
    : !!tokens.accessToken && !!tokens.refreshToken && isClient

  const role = isLoggedIn ? getUserRoleFromToken(tokens.accessToken) : null

  return { isLoggedIn, role }
}

export default useAuth
