import clsx from 'clsx'
import MaterialSymbol from '../MaterialSymbol'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  kind?: 'basic' | 'filter'
  text: string
  lineClamp?: boolean
  selected?: boolean
}

const Chip = ({
  kind = 'basic',
  text,
  lineClamp = true,
  selected = false,
  ...rest
}: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        'inline-block',
        'px-3',
        'py-[6px]',
        'border',
        'border-grey-400',
        'rounded-full',
        'select-none',
        { 'text-lavendar-700 bg-lavendar-100 border-none': selected },
      )}
      {...rest}
    >
      <span className="flex items-center">
        <MaterialSymbol
          name="check"
          size={20}
          className={clsx('mr-2', 'fill-lavendar-700', 'hidden', {
            block: selected,
          })}
        />
        <span
          className={clsx(
            'block',
            'max-h-[21px]',
            'overflow-y-hidden',
            'prose-p3',
            {
              'line-clamp-1': lineClamp,
            },
          )}
        >
          {text}
        </span>
        {kind === 'filter' && (
          <MaterialSymbol name="expand_more" size={20} className="ml-2" />
        )}
      </span>
    </button>
  )
}

export default Chip
