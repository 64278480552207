import AppleLoginButton from '@/components/AppleLoginButton'
import { KakaoLoginButton, NaverLoginButton } from '@/components/AuthButton'
import { URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import useSocialLogin from '@/hooks/useSocialLogin'

const LoginMenu = () => {
  const { isLoggedIn } = useAuth()
  const { handleKakaoLogin, handleNaverLogin } = useSocialLogin()
  if (isLoggedIn) return null

  return (
    <div className="flex flex-col border-b-[10px] border-grey-100 py-5">
      <p className="px-md">
        3초 만에 로그인하고 <br />
        <strong>암 전문가의 도움을</strong> 받으세요.
      </p>
      <div className="mt-3 px-md flex flex-col gap-3">
        <KakaoLoginButton
          data-ga="menu_kakao"
          size="md"
          text="카카오로 시작하기"
          onClick={() => handleKakaoLogin({ redirectPath: URL_PATH.MyCare })}
        />
        <NaverLoginButton
          data-ga="menu_naver"
          size="md"
          text="네이버로 시작하기"
          onClick={() => handleNaverLogin({ redirectPath: URL_PATH.MyCare })}
        />
        <AppleLoginButton
          data-ga="menu_apple"
          size="md"
          text="Apple로 시작하기"
        />
      </div>
    </div>
  )
}

export default LoginMenu
