import useSWR from 'swr'
import { z } from 'zod'
import { API_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { handleSWR } from '@/utils/httpClient/handleSWR'

const schema = z.array(
  z.object({
    id: z.number(),
    title: z.string(),
    body: z.string(),
    send_at: z.string().datetime({ offset: true }),
    read_at: z.nullable(z.string().datetime({ offset: true })),
    user: z.number(),
    deeplink: z.nullable(z.string()),
  }),
)

export type Notices = z.infer<typeof schema>

/**
 * 내 알림 목록을 가져오는 hook
 * @returns data - 내 알림 목록
 * @returns hasUnreadNotifications - 읽지 않은 알림이 있는지 여부
 */
const useMyNotifications = () => {
  const { isLoggedIn } = useAuth()
  const { data, mutate } = useSWR<Notices>(
    isLoggedIn ? API_PATH.MyNotices : null,
    handleSWR,
    {
      revalidateOnFocus: false,
    },
  )

  return {
    data,
    hasUnreadNotifications: data?.some((notice) => !notice.read_at),
    mutate,
  }
}

export default useMyNotifications
