import Image from 'next/image'
import clsx from 'clsx'
import { S3_IMAGE_URL } from '@/config'
import { ToastAction, ToastLink } from './Button'

type BaseProps = {
  title: string
  description?: string
  appToast?: boolean
}

type LinkProps = {
  link: {
    name: string
    href: string
  }
}

type ActionProps = {
  action: {
    name: string
    onClick: () => void
    gaSelector?: string
  }
}

export type ToastProps =
  | BaseProps
  | (BaseProps & LinkProps)
  | (BaseProps & ActionProps)

const isLinkProps = (props: ToastProps): props is BaseProps & LinkProps => {
  return (props as LinkProps).link !== undefined
}

const isActionProps = (props: ToastProps): props is BaseProps & ActionProps => {
  return (props as ActionProps).action !== undefined
}

const ToastContent = ({
  title,
  description,
}: {
  title: string
  description?: string
}) => {
  return (
    <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-1')}>
      <div className={clsx('flex', 'items-center', 'gap-1')}>
        <p className="text-white">{title}</p>
      </div>
      {description && <p className="text-grey-200 prose-p3">{description}</p>}
    </div>
  )
}

const AppToastContent = ({ title }: { title: string }) => {
  return (
    <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-1')}>
      <div className={clsx('flex', 'items-center', 'gap-1')}>
        <span className="flex-none w-9">
          <Image
            src={`${S3_IMAGE_URL}/lunit_care_logo_symbol_circular.png`}
            alt=""
            width={24}
            height={24}
          />
        </span>
        <p className="text-white flex-1 grow">{title}</p>
      </div>
    </div>
  )
}

/**
 * @param title 토스트 제목
 * @param description 토스트 설명
 * @param appToast 앱 토스트 여부. 모바일 앱 닫기 전 보여주는 토스트에는 앱 아이콘이 포함된다.
 */
const Toast = (props: ToastProps) => {
  const { appToast = false, title, description } = props

  return (
    <div
      className={clsx(
        'flex',
        'flex-row',
        'justify-between',
        appToast && 'z-[9999] relative',
      )}
    >
      {appToast ? (
        <AppToastContent title={title} />
      ) : (
        <ToastContent title={title} description={description} />
      )}
      {isLinkProps(props) && (
        <div className="flex gap-[6px]">
          <ToastLink href={props.link.href}>{props.link.name}</ToastLink>
        </div>
      )}
      {isActionProps(props) && (
        <div className="flex gap-[6px]">
          <ToastAction
            onClick={props.action.onClick}
            data-ga={props.action.gaSelector}
          >
            {props.action.name}
          </ToastAction>
        </div>
      )}
    </div>
  )
}
export default Toast
