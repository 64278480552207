import toast, { useToasterStore } from 'react-hot-toast/headless'
import Toast from '@/v1/Toast'
import type { ToastProps } from '@/v1/Toast'

const DEFAULT_BOTTOM_POSITION = 24
const DURATION_TIME = 3000
const DEFAULT_Z_INDEX = 10

/**
 * @example
 * v1/Toast 스토리북 참고
 */
const useToast = () => {
  //useToasterStore를 이용하여 toasts를 가져옵니다.
  const { toasts } = useToasterStore()

  /**
   * 토스트 메시지를 처리하는 함수입니다.
   * bottom은 값을 지정하지 않으면 기본값은 24입니다.
   * 토스트 너비는 wrapper의 너비로 지정하므로, 페이지의 PaddingWrapper 확인 필요!!!
   *
   * @example
   * showToast({
   *  title: '타이틀',
   *  description: '메세지',
   *  bottom: 50,
   * })
   *
   */
  const showToast = (
    props: ToastProps & {
      bottom?: number
      zIndex?: number
      width?: number
    },
  ) => {
    // 여기서 toasts의 데이터는 toast 실행되기 전인 이전 toasts의 data를 바라보고 있으므로
    // toasts.length가 1이상일 경우, toasts[0]를 dismiss 시켜줍니다.
    // toasts array에 toast가 추가되는 형식은 unshift입니다.
    if (toasts.length >= 1) {
      const prevToastId = toasts[0].id
      toast.dismiss(prevToastId)
    }

    // 토스트 너비를 wrapper의 너비로 지정한다.
    // wrapper가 없으면 Toaster 컴퍼넌트 기본 설정 너비로 지정한다.
    const wrapperWidth = document.querySelector('.js-wrapper')?.clientWidth

    toast(<Toast {...props} />, {
      duration: DURATION_TIME,
      style: {
        bottom: props.bottom ?? DEFAULT_BOTTOM_POSITION,
        width: props.width ?? wrapperWidth,
        zIndex: props.zIndex ?? DEFAULT_Z_INDEX,
      },
    })

    // toast에 액션 버튼이 있는 경우 자동으로 사라지지 않는 이슈가 있다.
    // 따라서 직접 setTimeout으로 제거해준다.
    setTimeout(() => {
      toast.remove()
    }, DURATION_TIME)
  }

  const hideToast = () => {
    toast.dismiss()
  }

  return { showToast, hideToast }
}

export default useToast
