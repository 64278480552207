import type { Options } from 'ky-universal'
import { mutate } from 'swr'
import { handleRequest } from '@/utils/httpClient/handleRequest'

/**
 * useSWR의 공통 fetcher 함수. handleRequest를 사용하되,
 * onRetry 콜백으로 muatate를 호출하여 revalidate 한다.
 * @param url - 요청 URL
 * @param options - ky options
 * @returns 응답 값
 *
 * @example: fetacher function for SWR
 * const { data, error, mutate } = useSWR<Post>(POST_URL, handleSWR, { revalidateOnFocus: false} )
 */
export const handleSWR = async <T>(
  url: string,
  options: Options,
): Promise<T> => {
  try {
    return await handleRequest<T>(url, options, () => {
      mutate(url)
    })
  } catch (error) {
    throw error
  }
}
