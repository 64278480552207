import type { TextButtonStyle } from '@/v1/TextButton/types'

// todo: change to defined theme color
export const textButtonStyle: TextButtonStyle = {
  layout:
    'flex justify-center items-center flex-wrap gap-[6px] cursor-pointer disabled:cursor-not-allowed',
  size: {
    large: 'h-full px-[6px] py-3 text-[18px]',
    medium: 'h-full px-1 py-2 text-[16px]',
    small: 'h-full px-1 py-1 text-[14px]',
  },
  color: {
    plane:
      'text-lavendar-500 hover:text-lavendar-700 [&_svg]:fill-lavendar-500 [&_svg]:hover:fill-lavendar-700',
    underline:
      'text-grey-700 hover:text-lavendar-500 [&_svg]:fill-grey-700 [&_svg]:hover:fill-lavendar-500',
  },
  disabled: 'text-grey-400 [&_svg]:fill-grey-400',
}
export const textUnderlineStyle = 'underline underline-offset-2'
