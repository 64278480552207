import Link from 'next/link'
import clsx from 'clsx'
import { URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import useMyNotifications from '@/hooks/useMyNotifications'
import { isWebview } from '@/utils'
import NewNoti from '@/v1/NewNoti'

/**
 * GNB의 알림 링크
 * 웹뷰 또는 로그인 하지 않은 경우는 노출하지 않는다.
 * GNB가 닫힌 경우네는 API_PATH.MyNotices 호출하지 않기 위해 컴퍼넌트로 분리한다.
 */
const NewNotiLink = ({ onClick }: { onClick: () => void }) => {
  const isApp = isWebview()
  const { isLoggedIn } = useAuth()
  const { hasUnreadNotifications } = useMyNotifications()

  if (isApp) return null
  if (!isLoggedIn) return null

  return (
    <Link
      href={URL_PATH.MyNotifications}
      data-ga="menu_notifications"
      className={clsx('block', 'py-1.5', 'relative', 'font-bold', 'text-lg')}
      onClick={onClick}
    >
      알림
      <NewNoti
        className={clsx('top-[9px]', 'left-[33px]', {
          hidden: !hasUnreadNotifications,
        })}
      />
    </Link>
  )
}

export default NewNotiLink
