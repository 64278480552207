import { ComponentPropsWithoutRef } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import ChevronIcon from '@/components/NewIcons/ChevronIcon'
import { textButtonStyle } from '@/v1/TextButton/styles'

const BUTTON_STYLE = [
  textButtonStyle.layout,
  'w-full',
  'h-full',
  'font-medium',
  'prose-p3',
  'text-lavendar-300',
].join(' ')

/* v1/TextButton의 색상은 lavendar-500입니다.Toast의 TextButton 색상은 lavendar-300이므로
Toast의 Textbutton은 별개로 구현합니다.*/
export const ToastAction = ({
  onClick,
  children,
  ...rest
}: ComponentPropsWithoutRef<'button'> & {
  onClick?: () => void
}) => {
  return (
    <button
      type="button"
      className={clsx(BUTTON_STYLE)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

export const ToastLink = ({
  href,
  children,
}: ComponentPropsWithoutRef<'a'> & {
  href: string
}) => {
  return (
    <Link
      href={href}
      className={clsx(BUTTON_STYLE, '[&_svg]:fill-lavendar-300')}
    >
      {children}
      <ChevronIcon width={16} height={16} />
    </Link>
  )
}
